import { gtag, observeFirstViewportIntersection } from "#js/components/utils"
import { OfferCard } from "#js/components/OfferCard"

export class OfferSliderCard extends OfferCard {
  static get properties() {
    return {
      sliderPosition: { type: Number },
    }
  }

  getBubbles() {
    if (this.offer.type === "expert") {
      return super.getBubbles()
    } else {
      return ""
    }
  }

  firstUpdated() {
    if (!this.offer) {
      console.warn(
        "<offer-slider-card> component rendered without properties assigned.",
      )
      return
    }
    observeFirstViewportIntersection(
      this.sendViewPromotion.bind(this),
      this,
    )
  }

  // we don't want to send the select_item event for the slider, since we send select_promotion already.
  sendSelectItemOfferCard(event, item) {
    gtag("event", "select_promotion", {
      items: [{
        item_name: item.title,
        item_id: item.id,
        item_brand: item.provider_title,
        affiliation: item.brand_name,
        quantity: 1,
      }],
      promotion_name: this.promotionName,
      promotion_id: this.promotionId,
      creative_name: event.target.tagName,
      creative_slot: this.sliderPosition,
    })
  }

  sendViewPromotion() {
    gtag("event", "view_promotion", {
      items: [{
        item_name: this.offer.title,
        item_id: this.offer.id,
        item_category: this.offer.activity_type,
        promotion_id: this.promotionId,
        promotion_name: this.promotionName,
        creative_slot: this.sliderPosition,
        item_brand: this.offer.provider_title,
        affiliation: this.offer.brand_name,
        quantity: 1,
      }],
    })
  }
}

globalThis.customElements.define("offer-slider-card", OfferSliderCard)
